import React from 'react';

class NotFound extends React.Component {
  render() {
    return (<div className="article-preview">
      <h2>Oopsies!?!? :(</h2>
      <p>Hum, are you sure you are in the right place? Just asking. If so, be assured I am already looking into it!</p>
    </div>)
  }
}


export default NotFound;