import React from 'react';
import { useAlert } from "react-alert";

const Share = (props) => {
  const alert = useAlert();
  if (!props) {
    return (<></>)
  }

  return (
  <div className="share-wrapper">
    <h3>Share this post</h3>
    <div className="share">
      <span>
        <a style={{ color: 'inherit', textDecoration: 'none' }} href={`https://twitter.com/intent/tweet?url=${window.location.href}`}><i className="bi bi-twitter" /></a>
      </span>
      <span>
        <a style={{ color: 'inherit', textDecoration: 'none' }} href={`mailto:?subject=${props.article.title}&body=${window.location.href}`}><i className="bi bi-envelope" /></a>
      </span>
      <span>
        <a style={{ color: 'inherit', textDecoration: 'none' }} href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}><i className="bi bi-linkedin" /></a>
      </span>
      <span onClick={() => {alert.show("Link copied!")}}>
        <i className="bi bi-link-45deg" />
      </span>
    </div>
  </div>)
}
export default Share;