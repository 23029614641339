import React from 'react';
import history from 'history/browser';

import Home from './routes/Home';
import About from './routes/About';
import Article from './routes/Article';
import Articles from './routes/Articles';
import TagResults from './routes/TagResults';
import Publications from './routes/Publications';

import NotFound from './components/NotFound';
import ReactGA from 'react-ga4';

import './assets/light.css';
import './assets/dark.css';
import './assets/common.css';


ReactGA.initialize('G-8PJFFDNBYJ');
class App extends React.Component {


  constructor(props) {
    super(props);
    this.handleRoutes = this.handleRoutes.bind(this);
  }

  componentDidMount() {
    this.handleRoutes(window.location);
  }

  unlisten = history.listen(({ location, action }) => this.handleRoutes(location));

  handleRoutes(location) {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
    switch (location.pathname) {
      case "/":
        this.setState( {current: <Home />});
        break;
      case "/about":
        this.setState( {current: <About />});
        break;
      case "/post":
        let id = new URLSearchParams(location.search).get("id");
        this.setState( {current: <Article id={id} />});
        break;
      case "/posts":
        this.setState( {current: <Articles />});
        break;
      case "/tags":
        let tag = new URLSearchParams(location.search).get("tag");
        this.setState( {current: <TagResults tag={tag} />});
        break;
      case "/publications":
        this.setState( {current: <Publications />});
        break;
      default:
        this.setState( {current: <NotFound />});
    }
  }

  state = {current: <Home/>}

  render() {
    return this.state.current;
  }
}

export default App;