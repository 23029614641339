import React from 'react';
import history from 'history/browser';

class Anchor extends React.Component {
  goTo(path, kvPairs) {
    if (!kvPairs) {
      history.push({pathname: `/${path}`});
      return;
    }

    let search = "?";
    for(let key in kvPairs) {
      search += `${key}=${kvPairs[key]}&`
    }

    history.push({pathname: `/${path}`, search: search.slice(0, -1)});
  }

  render() {
    return (
      <span 
        className={this.props.className}
        style={{cursor: "pointer"}} 
        onClick={()=>this.goTo(
          this.props.path, 
          this.props.search)}>
            {this.props.children}
      </span>)
  }
}
export default Anchor;