import React from 'react';

class TableOfContext extends React.Component {
  constructor(props) {
    super(props);
    this.getToc = this.getToc.bind(this);
    this.scrollIntoView = this.scrollIntoView.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  scrollToTop(id) {
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  scrollIntoView(id) {
    var element = document.getElementById(id);
    var windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    if (!element) {
      return;
    }

    window.scroll({
      top: this.getElementTop(element) - ((windowHeight / 3) - 1), 
      left: 0, 
      behavior: 'smooth'
    });
  }

  getElementTop(element) {
    var elementTop = 0;

    do {   
        elementTop += element.offsetTop;
    } while (element === element.offsetParent);
    return elementTop;
  }

  getToc(content) {
    return content.match(/<h2 id=(.*?)<\/h2>/g);
  }

  render() {
    if (!this.props.content) {
      return (<></>);
    }

    let toc = this.getToc(this.props.content).map((element, index)=>{
      let id = `head-${index}`;
      return (
        <div onClick={() => this.scrollIntoView(id)} className="toc-item" key={index}>{element.substring(16, element.length - 5)}</div>
      );
    });

    return (
      <div style={{ "gridArea": "toc", "cursor": "pointer" }} className="toc">
        <div className="sticky-block-schroller">
          <div>
            <div className="sticky-block">
              <div onClick={() => this.scrollToTop()} className="toc-item toc-title toc-item-highlighted">{this.props.title}</div>
              {toc}
            </div>
          </div>
        </div>
      </div>)
  }
}
export default TableOfContext;