import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './ops/reportWebVitals';

import App from './App'
import Footer from './components/Footer'
import Header from './components/Header'

import { Provider } from "react-alert";
import AlertTemplate from "./components/AlertTemplate";

const options = {
  timeout: 3000
};

ReactDOM.render(
    <Header/>,
  document.getElementById('header')
);

ReactDOM.render(
  <Provider template={AlertTemplate} {...options}>
    <App />
  </Provider>,
  document.getElementById('main')
);

ReactDOM.render(
  <Footer/>,
  document.getElementsByTagName('footer')[0]
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
