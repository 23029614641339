import React from 'react';
import publications from '../assets/publications.json';


class Publications extends React.Component {
  state = { publications: [] }

  componentDidMount() {    
    this.getPublications();
  }

  getPublications() {
    let finalPublications = []

    publications.forEach((publication)=> {
      fetch(`content/${publication.contentLocation}`)
        .then((r) => r.text())
        .then(content => {
          finalPublications.push(
            {
              content: content,
              publication: publication
            }
          )
          this.setState({publications: finalPublications});
        });
    });
  }

  render() {
    let publications = this.state.publications.map((publication) => {
      return (
        <div className="article-preview">
          <h2 className="title" id={publication.publication.title}>{publication.publication.title}</h2>
            <div className="sub-header">
              <span className="date">{publication.publication.createDate}</span>
            </div>
          <div className="publication">
            <div dangerouslySetInnerHTML={{ __html: publication.content }} />
          </div>
        </div>
      )
    });
    return (
    <div className="article-previews">
      {publications}
    </div>);
  }
}
export default Publications;