import React from 'react';
import moment from 'moment'
import Tags from '../components/Tags'
import Anchor from '../components/Anchor';

class ArticlePreview extends React.Component {

  render() {
    let article = this.props.article;
    let date = moment(article.createDate).format("Do MMM YYYY");
    let hero = !article.hero?(<></>):(
      <Anchor path="post" search={{"id": article.id}}>
        <div style={{"gridArea": "hero"}}>
          <img alt={article.title} style={{width: "100%"}} src={article.hero} />
        </div>
      </Anchor>
    )

    return (
      <div className="article-preview">
        {hero}
        <Anchor path="post" search={{"id": article.id}}>
          <h2 className="title">
            {article.title}
          </h2>
        </Anchor>
        <div className="sub-header">
            <span>
              <span>by </span><span className="author">{article.author}</span>
            </span>
            <span className="date">{date}</span>
            <span className="read-length">
                <i className="bi bi-clock"></i> {article.minutes + " min read"}
            </span>
        </div>
        <Tags tags={article.tags} />
        <div className="summary">
          <p>{article.summary}</p>
        </div>
        <p style={{ textAlign: "right" }}>
          <Anchor className="link" path="post" search={{"id": article.id}}>
            Read more
          </Anchor>
        </p>
      </div>
    )
  }
}
export default ArticlePreview;