import React from 'react';

class Logo extends React.Component {

  render() {
    return (
      <svg className="logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 203 168" style={{ "enableBackground": "new 0 0 203 168" }}>
          <g>
            <polygon className="logo-polygon" points="184.12,158.92 120.47,7.12 166.74,7.12 179.16,35.12 183.67,35.12 183.67,1.71 118.2,1.71 88.44,1.71 19.33,1.71 19.33,35.12 23.84,35.12 36.49,7.12 86.06,7.12 91.51,7.12 91.61,7.12 129.04,98.43 131.3,104.07 154.09,158.92 136.26,164.11 136.26,166.83 201.5,166.83 201.5,164.11"/>
            <polygon className="logo-polygon" points="19.79,158.92 1.5,164.11 1.5,166.83 49.58,166.83 49.58,164.11 25.88,158.92 65.86,66.82 68.35,61.17 72.26,52.12 68.92,46.44"/>
          </g>
      </svg>)
  }
}
export default Logo;