import React from 'react';
import ArticlePreview from '../components/ArticlePreview';
import articles from '../assets/articles.json';


class Articles extends React.Component {
  state = {articles: articles}

  render() {
    return (<>
      <div className="article-previews">
        {this.state.articles.map((article, index) => (
          <ArticlePreview key={index} article={article} />
        ))}
      </div>
    </>)
  }
}
export default Articles;