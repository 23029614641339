import React from 'react';
import Anchor from './Anchor';

class Tags extends React.Component {
    
  render() {
    return (
      <div className="tags">
        {this.props.tags.map((tag, index) => (
          <Anchor
            path="tags" 
            search={{"tag": tag}} 
            key={index} 
            className="tag">
              {tag}
            </Anchor>
        ))}
      </div>
    )
  }
}
export default Tags;