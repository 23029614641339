import React from 'react';
import ArticlePreview from '../components/ArticlePreview';
import articles from '../assets/articles.json'
import NotFound from '../components/NotFound'


class TagResults extends React.Component {

  state = {
    tag: "",
    articles: articles
  }

  render() {
    let articles = (this.props.tag === "") ? this.state.articles : 
      this.state.articles.filter((article, index) => article.tags.includes(this.props.tag))

    if (!articles || articles.length === 0) {
      return (<NotFound />);
    }

    return (<>
      <div className="article-previews">
      <p style={{marginTop: "0px"}}>Posts related to: "{this.props.tag}"</p>
        {articles.map((article, index) => (
          <ArticlePreview key={index} article={article} />
        ))}
      </div>
    </>);
  }
}
export default TagResults;