import React from 'react';
import Anchor from './Anchor';
import Menu from './Menu'
import SearchResults from './SearchResults'


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.search = this.search.bind(this);
    this.callback = this.callback.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
  }

  state = {
    wrapperClass: "menu-wrapper",
    backgroundClass: "menu-background",
    menuClass: "menu",
    search: <></>,
    closeSearch: <></>,
    searchQuery: ""
  };

  callback(newState) {
    this.setState(newState);
  }

  toggleMenu() {
    this.setState({
      wrapperClass: "menu-wrapper-active",
      backgroundClass: "menu-background-active",
      menuClass: "menu-active"
    });
  }

  closeSearch() {
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove("overflow-hide");
    this.setState({
      search: <></>,
      closeSearch: <></>,
      searchQuery: ""
    });
  }

  search(e) {
    var body = document.getElementsByTagName('body')[0];
    body.classList.add("overflow-hide");
    this.setState({
      search: <SearchResults query={e.target.value} callback={this.closeSearch}/>,
      closeSearch: <div className="close-search" onClick={this.closeSearch}><i className="bi bi-x"></i></div>,
      searchQuery: e.target.value
    });
  }

  focusInput() {
    document.getElementById("search-input").focus();
  }

  render() {
    return (<>
      <Menu classes={this.state} callback={this.callback}/>
      {this.state.search}
      <header className="header-visible">
        <div className="hamburger" onClick={this.toggleMenu}>
          <i className="bi bi-list"></i>
        </div>
        <Anchor className="name" path="">
            ARIS TZANIS
        </Anchor>
        <div className="search-icon" onClick={this.focusInput}>
          <i className="bi bi-search"></i>
        </div>
        <input autoComplete="off" id="search-input" className="search-input" placeholder="Search here..." onChange={(e)=>{this.search(e)}} value={this.state.searchQuery}></input>
        {this.state.closeSearch}
      </header>
    </>)
  }
}
export default Header;