import React from 'react';
import articles from '../assets/articles.json'
import publications from '../assets/publications.json'
import Anchor from './Anchor';

class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.getArticles = this.getArticles.bind(this);
    this.getPublications = this.getPublications.bind(this);
    this.highlightResults = this.highlightResults.bind(this);
  }

  getArticles(query) {
    if (!query) {
      return undefined;
    }

    let results = this.getCollection(query, articles);
    if (results.length <= 0) {
      return undefined;
    }

    return results.map(result => {
      return (
        <div className="search-result" onClick={this.props.callback}>
          <Anchor path="post" search={{"id": result.id}}>
            <h3 dangerouslySetInnerHTML={{ __html: result.title }} />
            <p dangerouslySetInnerHTML={{ __html: result.summary }} />
          </Anchor>
        </div>)
    });
  }

  getPublications(query) {
    if (!query) {
      return undefined;
    }

    let results = this.getCollection(query, publications);
    if (results.length <= 0) {
      return undefined;
    }

    return results.map(result => {
      return (
        <div className="search-result" onClick={this.props.callback}>
          <Anchor path="publications">
            <h3 dangerouslySetInnerHTML={{ __html: result.title }} />
            <p dangerouslySetInnerHTML={{ __html: result.summary }} />
          </Anchor>
        </div>)
    });
  }

  getCollection(query, collection) {
    let results = [];
    for (let i = 0; i < collection.length; i++) {
      let article = collection[i];
      let titled = this.highlightResults(article.title, query, "span", "highlighted-search-result");
      let summary = this.highlightResults(article.summary, query, "span", "highlighted-search-result");
      if (!titled.is && !summary.is) {
        continue;
      }
      results.push({
        id: article.id,
        title: titled.text,
        summary: summary.text
      });
    }
    return results;
  }

  highlightResults(str, query, element, className) {
    let result = "";
    let has = false;

    let i = 0;
    while (i < str.length) {
      let sub = str.substring(i, i + query.length);

      if (this.equals(sub, query)) {
        result += `<${element} class="${className}">${sub}</${element}>`;
        i += (query.length - 1);
        has = true;
      } else {
        result += str[i];
      }
      i += 1;
    }

    return { is: has, text: result };
  }

  equals(str1, str2) {
    return str1.toLowerCase() === str2.toLowerCase();
  }

  render() {
    let articleElements = this.getArticles(this.props.query);
    let publicationElements = this.getPublications(this.props.query);
    if (!articleElements && !publicationElements && this.props.query) {
      return (
        <div className="search-results search-results-width">
          <div className="search-result">
              <p>No results for: "{this.props.query}"</p>
            </div>
        </div>
      )
    }

    return (
      <div className="search-results search-results-width">
        {
          articleElements ? (<>
            <h1 style={{marginLeft: "8px"}}>Posts</h1>
            {articleElements}
          </>) : (<></>)
        }
        
        {
          publicationElements ? (<>
            <h1 style={{marginLeft: "8px"}}>Publications</h1>
            {publicationElements}
          </>) : (<></>)
        }
      </div>
    )
  }
}
export default SearchResults;