import React from 'react';
import Logo from './Logo';
import Anchor from '../components/Anchor';
import Theme from './Theme';
import { ENVELOPE, TWITTER, BOOK, PERSON, LINKED_IN } from './Icons';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.toggleBackground = this.toggleBackground.bind(this);
  }

  componentDidMount() {
    document.getElementsByClassName("menu-background")[0].addEventListener("transitionend", this.transitionEnd.bind(null, this.props), false);
  }

  /**
   * It will be triggered when the background becomes inactive, so the z-index changes
   * after the transition is complete. It uses the callback to update the classes
   * 
   * @param props Contains the callback to parent
   * @param event Not used
   */
  transitionEnd(props, event) {
    let isActive = document.getElementsByClassName("menu-background").length === 0;

    if (isActive) {
      return;
    }

    props.callback({
      wrapperClass: "menu-wrapper"
    });
  }

  toggleBackground() {
    this.props.callback({
      backgroundClass: "menu-background",
      menuClass: "menu"
    });
  }

  render() {
    let classes = this.props.classes;

    return (
      <div className={classes.wrapperClass}>
        <div className={classes.backgroundClass} onClick={this.toggleBackground} />
        <div className={classes.menuClass}>
          <div className="menu-items">
            <div className="menu-item" onClick={this.toggleBackground}>
              <Anchor path="" className="menu-item-wrapper">
                <span className="menu-item-icon"><Logo /></span>
                <span className="menu-item-text">Home</span>
              </Anchor>
            </div>
            <div className="menu-item" onClick={this.toggleBackground}>
              <Anchor className="menu-item-wrapper" path="about">
                <span className="menu-item-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  </svg></span>
                <span className="menu-item-text">About</span>
              </Anchor>
            </div>
            <div className="menu-item" onClick={this.toggleBackground}>
              <Anchor className="menu-item-wrapper" path="publications">
                <span className="menu-item-icon">{BOOK}</span>
                <span className="menu-item-text">Publications</span>
              </Anchor>
            </div>

            <Theme />
            <div className="last-menu-item">
              <span className="menu-item-icon">
                <a href="https://linkedin.com/in/aris-tzanis-0112b920" style={{ color: 'inherit', textDecoration: 'none' }}>{LINKED_IN}</a>
              </span>
              
              <span className="menu-item-icon">
                <a href="https://twitter.com/ArisTzanis" style={{ color: 'inherit', textDecoration: 'none' }}>{TWITTER}</a>
              </span>

              <span className="menu-item-icon">                
                <a href="mailto:atzanis@yahoo.com" style={{ color: 'inherit', textDecoration: 'none' }}>
                  <span className="menu-item-icon">{ENVELOPE}</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Menu;