import React from 'react';


class About extends React.Component {

    render() {
        return (<>
          <div className="article-previews">
            <div className="article-preview">
              <img width="200" src="/img/about-pic.jfif" height="200" alt="Aris Tzanis" className="about-image-wrapper"/>
              <p>
                Hello, and welcome to my blog! Through the posts here, I am sharing some of my experiences with various technologies ranging from ML, IoT, AWS, and more. Feel free to reach out with any questions/comments/feedback.
              </p>
                
              <p> 
                As far as my experience goes, I've been coding since I got my first computer, a ZX Spectrum, when my first game fit in a cassette. I have more than 10 years industry experience, on frontend, backend, big data, and ML. More details, in my <a href="/other/resume.pdf">resume</a>.
              </p>
            </div>
          </div>
        </>)
    }
}
export default About;