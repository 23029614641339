import React from 'react';

class Footer extends React.Component {

  render() {
    let date = "© " + new Date().getFullYear() + " Aris Tzanis";
    return (
        <div>
            {date}
        </div>)
  }
}
export default Footer;