import React from 'react';
import articles from '../assets/articles.json'
import Share from '../components/Share'
import TableOfContext from '../components/TableOfContext'
import moment from 'moment'
import NotFound from '../components/NotFound'
import Tags from '../components/Tags'


class Article extends React.Component {

  state = {
    content: "",
    article: "",
    toc: [],
    mounted: false
  }

  componentDidMount() {    
    this.getArticle();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
        this.getArticle();
    }
  }

  updateContent(content) {
    return content.replace(/<h2>(.*?)<\/h2>/g, ((head) => {
      var number = 0;
      return (head) => {
        return head.replace("<h2>", `<h2 id="head-${number++}">`);
      }
    })());
  }

  getArticle() {
    let id = this.props.id;
    
    let article = articles.find(article => article.id === id);
    if (!article) {
      this.setState({mounted: true});
      return;
    }

    fetch(`content/${article.contentLocation}`)
      .then((r) => r.text())
      .then(content => {
        let updated = this.updateContent(content);
        
        this.setState({ content: updated, article: article, mounted: true });
      });
  }

  render() {
    if (!this.state.mounted) {
      return (<></>);
    }
    if (!this.state.article) {
      return (<NotFound />);
    }

    let article = this.state.article;
    let date = moment(article.createDate).format("Do MMM YYYY");
    let hero = !article.hero?(<></>):(
      <div style={{"gridArea": "hero", "marginTop": "-58px"}}>
        <img alt={article.title} style={{width: "100%"}} src={article.hero} />
      </div>
    )


    return (<>

      <div className="article-wrapper">
        {hero}
        <div style={{ "gridArea": "title" }} className="article-header-wrapper">
          <div>
            <h1 className="title">{article.title}</h1>
            <div className="sub-header">
              <span>
                <span>by </span><span className="author">{article.author}</span>
              </span>
              
              <span className="date">{date}</span>

              <span className="read-length">
                <i className="bi bi-clock"></i> {article.minutes + " min read"}
              </span>
              {/* <span className="views">{article.views} views</span> */}
            </div>
            <Tags tags={article.tags}/>
          </div>
        </div>
        
        <TableOfContext content={this.state.content} title={article.title} />
        <div style={{ "gridArea": "gap1" }} />
        <div style={{ "gridArea": "content" }} className="article-view">
          <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
          <Share article={article} />
        </div>
        <div style={{ "gridArea": "gap2" }} />
      </div>
    </>)
  }
}
export default Article;